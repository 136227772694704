import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { UserService } from '../_services/user.service';


@Directive({ selector: '[appShowAuthed]' })
	export class ShowAuthedDirective implements OnInit {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private userService: UserService
	) {
		

	}

  	condition: boolean;

	ngOnInit() {
		this.userService.isAuthenticated.subscribe(
			(isAuthenticated) => {
					console.log(isAuthenticated);
				if ((isAuthenticated && this.condition) || (!isAuthenticated && !this.condition)) {
					this.viewContainer.createEmbeddedView(this.templateRef);
				}else{
					this.viewContainer.detach();
				}
			}
		);

		/*
		var isAuthenticated = false;
		var userData = JSON.parse(localStorage.getItem('userData'));

		if((userData!=null) && (userData.slug!=null)){
			isAuthenticated = true;
		}
		
		
		if (isAuthenticated && this.condition || !isAuthenticated && !this.condition) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}*/		
	}

	@Input() set appShowAuthed(condition: boolean) {
		this.condition = condition;
	}

}
