import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class HomeService {
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};
	sliderUrl			:	any		=	{};
	blockUrl			:	any		=	{};
	postCodeUrl			:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}
	
	/**This function are used for get the slider data **/
	getSliderData(){
		this.sliderUrl					=	this.SITE_API_URL;
		this.data.method_name			=	'listSlider';
		this.data.api_referrer			=	'web';
		return this.SharedService.getPost(this.sliderUrl,this.data);	
	}
	
	/**This function are used for get the block data **/
	getBlockData(slug:any){
		this.blockUrl					=	this.SITE_API_URL;
		this.data.method_name			=	'showBlock';
		this.data.api_referrer			=	'web';
		this.data.slug					=	 slug;
		return this.SharedService.getPost(this.blockUrl,this.data);	
	}
	
	/**This function are used for check post code service avilable or not**/
	checkPostCodeAvailabilityData(data:any){
		this.postCodeUrl					=	this.SITE_API_URL;
		data.method_name				=	'checkAvailability';
		data.api_referrer				=	'web';
		return this.SharedService.getPost(this.postCodeUrl,data);	
	}
	
	//handler function added 
    private handleError (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
}
