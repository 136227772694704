import { Component, OnInit, Input} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

declare var $:any;

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css'],

})
export class PaginationComponent implements OnInit {
	@Input() pageData: any;
	linkLimit	: number =	3
	constructor(public router: Router) { }

	ngOnInit() {
		
	}
	
	
}
