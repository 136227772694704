import { Component, OnInit } from '@angular/core';
import { RouterModule,Routes,Router } from '@angular/router';
import { environment } from '../environments/environment';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	title = '';
	SITE_IMAGE_URL: string = "";
	constructor(public router: Router,private userService:UserService) {
     	this.SITE_IMAGE_URL = environment.SITE_IMAGE_URL;
	}
	  
	ngOnInit() {
		this.userService.populate();
		
		$(document).keydown(function(event){
			if(event.keyCode==123){
				return false;
			}
			else if (event.ctrlKey && event.shiftKey && event.keyCode==73){        
					 return false;
			}
		});
	}
   
	/**This function are used for when activte the url then move window.*/
	onActivate(e, outlet){
		window.scrollTo(0, 0);
	}
	
	/**This function are used for disable right click **/
	onRightClick() {
		//return false;
	}
	
}



