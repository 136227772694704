import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from "../../../shared/service/shared.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { OrderService } from '../../../orders/service/order.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-trackparcalresult',
  templateUrl: './trackparcalresult.component.html',
  providers: [OrderService,SharedService]
})
export class TrackparcalresultComponent implements OnInit {
	
  /**Variable Decleartion **/
  trackingHistory		:	any		=	[];  
  bookingDetail			:	any		=	[];
  bookingWithTracking		:	any		=	[];

  isLightboxVisible: boolean = false; // Controls lightbox visibility
  selectedImage: string = '';         // Holds the image source for the lightbox
  
  constructor(public router: Router, private formBuilder: FormBuilder,public route: ActivatedRoute,public orderService: OrderService,private http: HttpClient) { }
  
  ngOnInit() {
		this.getParcelTrackingHistory($("#consignment_number_val").val());
  }

  // Method to open the lightbox with the selected image
  openLightbox(imageSrc: string) {
    this.selectedImage = imageSrc;
    this.isLightboxVisible = true;
  }

  // Method to close the lightbox
  closeLightbox() {
    this.isLightboxVisible = false;
  }
  
  downloadImage(imageUrl: string) {
     this.http.get('https://cors-anywhere.herokuapp.com/'+imageUrl, { responseType: 'blob' }).subscribe(
      (response) => {
        const blob = response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'delivery_product_image.jpg';
        a.click();
      },
      (error) => {
        console.error('Error downloading image', error);
      }
    );
}	
  
  /*Function to get parcel tracking history */
   getParcelTrackingHistory(consignment_number){
    let formData        =   new FormData();
    formData.append('consignment_number',consignment_number);		
    this.orderService.trackParcel(formData).subscribe((response: any) => {
	if(response.data.success == true){
		 this.bookingWithTracking = response.data.data.map(record => ({
               		 booking_detail: record.booking_detail,
                	 track_parcel_history: record.track_parcel_history || [] // Ensure it doesn't break when empty
            	 }));		
	}
	
     
     });
   }

} // End of Class
