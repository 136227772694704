import { Component, OnInit, Input } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { UserService } from "../../../../_services/user.service";
import { LoginUserService } from "../../../user/service/login_user.service";
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/validation.service';
declare var $:any;


@Component({
	selector: 'left-menu',
	templateUrl: './leftmenu.component.html',
	styleUrls: ['./leftmenu.component.css'],
	providers : [LoginUserService,UserService]
})
export class LeftMenuComponent implements OnInit {
	userData	:	any		=	[];
	SITE_IMAGE_URL: string	 	= "";
	updateProfileToolTipText	:	string	=	'';	
	imageForm					:	FormGroup;
	imageFormStatus				:	boolean	= false;
	
	@Input() activeTab: any;

	constructor(public router: Router,private formBuilder: FormBuilder, private userService:UserService, private loginUserService:LoginUserService) {
		this.SITE_IMAGE_URL 					= 	environment.SITE_IMAGE_URL;
		this.updateProfileToolTipText			=	environment.UPDATE_PROFILE_TOOLTIP_TEXT;
	}

	ngOnInit() {
		if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
			this.getLoginUserData();
			this.createImageForm();
		}else{
			showErrorCenter('You are not allowed to perform this action.');
			this.router.navigate(['/']);
		}
		let that	=	this;
		$(document).on('click','#userDataUpdated',function(){
			that.getLoginUserData();
		});
		/*********** This code are used for tooltip initialization ************/
		setTimeout(function(){
			$('[data-toggle="tooltip"]').tooltip(); 
		},1000);
		$(document).on('click','.img-profile-add',function(){
			$('#profileImage').trigger('click');
		});

	}
	
	/* FUNCTION TO get login user data */
	public getLoginUserData(): any {
		if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
			this.userData				=	this.userService.getCurrentUser();
		}
	}


	/**This function are used for create signup form **/
	public createImageForm(){
		this.imageForm			=	this.formBuilder.group({ 
			   'image'		: [''],
		});
	}
	
	/**This function are used for change the file then get image file **/
	fileChangeEvent(event: any): void {
		let files = event.target.files;
		let imageValid	=	ValidationService.validateImageField(files);
		if(imageValid['status']	==	true){
			let file = event.target.files[0];
			this.imageForm.value.image		=	file;
			this.submitImageForm();
		}else{
			showErrorCenter(imageValid['message']);
			this.imageForm.value.image		=	null;
		}
	}
	 
	/**This function are used for submit the update profile image form**/
	public submitImageForm(){
		blockUI();	  
	   	/* Api are used for sace the profile image */	
		const formModel	= this.prepareSave();
		this.loginUserService.submitUpdateForm(formModel).subscribe((response: any) => {
			this.imageFormStatus	= response.data.success;
			if(this.imageFormStatus == true)
			{
				showSuccessCenter(response.data.message);
				this.userService.updateCurrentUserData();
				unblockUI();	
			}else{
				if(typeof(response.data.errors.image) != 'undefined'){
					showErrorCenter(response.data.errors.image);
				}
				unblockUI();
			}
		});	
	   	
	}


	
	
	
	/* FUNCTION TO PREPARE FORM BEFOR SUBMIT WITH ALL DATA & IMAGE ALSO */
	private prepareSave(): any {
		let input 			= new FormData();	
		let loginUserSlug	=	this.userService.getCurrentUser().slug;
		input.append('slug',loginUserSlug);
		input.append('image',this.imageForm.value.image)	
		input.append('method_name',	"updateProfileImage")		
		input.append('api_referrer',"web")			
		return input;
	}
}
