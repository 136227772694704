import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
declare var $:any;

@Directive({
  selector: '[appLogout]',
})
export class LogoutDirective {


constructor(private el: ElementRef,public router: Router,public userService: UserService) { }
  

@HostListener('click') onClick() {
	this.logout();
}


	/**function for logout */
	private logout(){ 
		let user_name	=	this.userService.getCurrentUser().full_name;
		this.userService.purgeAuth();
		showSuccessMessageTopRight(user_name+', '+environment.LOGOUT_MSG);
		/*setTimeout(function(){
			window.location.href = "/";
		},1000);*/
		this.router.navigate(['/']);
	}
}
