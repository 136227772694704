import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { SharedService } from "../../service/shared.service";
import { ResetPasswordService } from "../../service/resetpassword.service";


declare var $:any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
   providers : [ResetPasswordService,SharedService]
})
export class ResetpasswordComponent implements OnInit {
	
	SITE_IMAGE_URL					:	 string = "";
	Resetform						:	FormGroup;
	ResetError					:	any		=	{};
	ResetFormErrorMsg			:	any		=	{};
	RestError					:	any		=	{};
	ResetFormSuccessMessage		:	any		=	{};
	ResetFormStatus				:	any		=	{};
	passwordToolTipText			:	string	=	'';
	user_data				:	any	=	{};
  
  constructor(public router: Router,private formBuilder: FormBuilder,private ResetPasswordService: ResetPasswordService) {
    this.SITE_IMAGE_URL 		= 	environment.SITE_IMAGE_URL;
	this.passwordToolTipText				=	environment.VALID_PASSWORD_VALIDATION_MSG;
	
  }

  ngOnInit() {
	/*********** This is used for validate the login form ************/	
	this.Resetform			=	this.formBuilder.group({
		'password'				: ['', [Validators.required,ValidationService.passwordValidator]],
		'confirm_password'		: ['', [Validators.required,ValidationService.MatchPassword]],
		'user_data'				: [''],
	})
	
	 /*********** This code are used for tooltip initialization ************/
	 setTimeout(function(){
		$('[data-toggle="tooltip"]').tooltip(); 
	 },1000);
	
	
  }
  
  
  /*This function are used for reset the password form submit*/
	onResetFormSubmit(){
		if (this.Resetform.valid) { 
			this.Resetform.value.user_data		=	$('#user_data_after_submit_otp').val();
			this.ResetPasswordService.resetPassword(this.Resetform.value).subscribe((res: any) => {		
				this.ResetFormStatus	= res.data.success;
			 	if(this.ResetFormStatus == true)
			 	{
					this.ResetError			=	'';
					this.Resetform.reset();
					this.router.navigate(['/login']);
					setTimeout(function(){
						$('#reset_password').modal('hide');
						$('body').removeClass('modal-open');
					},1000);
					showSuccessMessageTopRight(res.data.message);
				}else{
					this.ResetError 	= res.data.message;
					showErrorMessageTopRight(this.ResetError);
			 	}
				unblockUI();
			});
			blockUI();
		} else {
			ValidationService.validateAllFormFields(this.Resetform);
		}
	}

}
