import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-payment-verification',
  templateUrl: './paymentverification.component.html',
})
export class PaymentverificationComponent implements OnInit {
	status							:	any			=	'';
	consignment_number				:	any			=	'';
	message							:	any			=	'';
	constructor(
		private route: ActivatedRoute, 
		public router: Router,
	) {
	}

	ngOnInit() {
		/*********** This is used for payment verification ************/	
		this.verifyPayments();		
	}
	
	/**This function are used for payment verification**/
	public verifyPayments(){
		blockUI();
		this.status 			= this.route.snapshot.params['status'];
		this.consignment_number = this.route.snapshot.params['consignment_number'];
		if(this.status == 'success'){
			this.message	=	environment.PAYMENT_SUCCESS_MSG;	
		}else if(this.status == 'failure'){
			showErrorMessageTopRight(environment.PAYMEMT_FAILURE_MSG);	
			this.router.navigate(['/']);
		}else if(this.status == 'invalid_access'){
			showErrorMessageTopRight(environment.PAYMENT_INVALID_MSG);	
			this.router.navigate(['/']);
		}else{
			showErrorMessageTopRight(environment.PAYMENT_PENDING_MSG);	
			this.router.navigate(['/']);
		} 
		unblockUI();
	}
}
