import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { SharedService } from "../../service/shared.service";
import { ResetPasswordService } from "../../service/resetpassword.service";


declare var $:any;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
   providers : [ResetPasswordService,SharedService]
})
export class ForgotComponent implements OnInit {
	
  SITE_IMAGE_URL: string = "";
  forgotForm						:	FormGroup;	
  forgotFormError					:	any		=	{};
  OtpForResetPasswordStatus			:	any		=	{};
  forgotType						:	any		=	{};
 
  
  constructor(public router: Router,private formBuilder: FormBuilder,private ResetPasswordService: ResetPasswordService) {
    this.SITE_IMAGE_URL 		= 	environment.SITE_IMAGE_URL;
	
  }

  ngOnInit() {
	
	/*********** This is used for validate the login form ************/	
	this.forgotForm			=	this.formBuilder.group({
		'type'										: ['', Validators.required],
		'forget_email_mobile_password'				: ['', Validators.required],
	})
  
  }
  
  /**This function are used for submit the get OTP for reset password **/
  public onForgotSubmit(){
	 blockUI();
	 if (this.forgotForm.valid) {
			this.forgotType	=	this.forgotForm.value.type;
			this.ResetPasswordService.getOtpForResetPassword(this.forgotForm.value).subscribe((res: any) => {
				this.OtpForResetPasswordStatus	=	res.data;
				if(this.OtpForResetPasswordStatus.success == true){
						this.forgotForm.reset();
						let that			=	this;
						$('body').removeClass('modal-open');
						$('#forgotModal').modal('hide');
						setTimeout(function(){
							unblockUI();
							$("#submit_otp_form_open").trigger('click');
							$('#user_data_id').val(res.data.email_mobile_data);
						},1000);	
						$("#otp_type").val(that.forgotType);
						showSuccessMessageTopRight(res.data.message);
						unblockUI();
				}else{
					unblockUI();
					this.forgotFormError 	= res.data.errors;
				}
		   });	
	 }else{
		unblockUI();
		ValidationService.validateAllFormFields(this.forgotForm);
	 }		 
 }
 
 
  

}
