import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { SharedService } from "../../service/shared.service";
import { ResetPasswordService } from "../../service/resetpassword.service";
import { UserService } from "../../../../_services/user.service";

declare var $:any;

@Component({
  selector: 'signup-verification',
  templateUrl: './signupverification.component.html',
   providers : [ResetPasswordService,SharedService]
})
export class SignupVerificationComponent implements OnInit {
	
	SITE_IMAGE_URL: string = "";
	signupOtpForm				:	FormGroup;	
	signupOtpFormStatus			:	boolean	=	false;
	timeLeft					:	string	=	'0:00 min left';
	isTimeOn					:	boolean	=	false;
	resendMailTime				:	number	=	0;
	signupOtpFormError			:	any		=	{};
	user_data					:	string	=	'';
	otpMobileForm					:	FormGroup;
	user_id						:	string	=	'';
	otpFormError				:	any		=	{};
	otpFormStatus				:	boolean	=	false;
	
  
	constructor(private userService: UserService, public router: Router,private formBuilder: FormBuilder,private ResetPasswordService: ResetPasswordService) {
		this.SITE_IMAGE_URL 		= environment.SITE_IMAGE_URL;
		this.resendMailTime			=	environment.RESEND_MAIL_TIME_IN_SECOND;
	}

	ngOnInit() {
		/*********** This is used for validate the login form ************/	
		
		this.createOtpForm();
		this.createOTPForMobileForm();
		let that	=	this;
		$(document).on('click','#signup_otp_form_open',function(){
			that.isTimeOn	=	true;
			that.timeCounter(that.resendMailTime);
			$('#signup_verify').modal('show');
			$('body').addClass('modal-open');
			$(".signup_resend").hide();
		});

		$(document).on('click','.login_verification',function(){
			$(".signup_resend").hide();
			let rel 				=	$('.login_verification').attr('data-rel');
			let validate_string		=	$('.login_verification').attr('data-url');
			if(rel	==	'mobile'){
				$("#signup_data_for_mobile").val(validate_string);	
				that.sendOTP();
			}else{
				$("#signup_data").val(validate_string);	
				that.resendSignupOtp();
				
			}
		});
		$('#signup_verify').on('hidden.bs.modal', function () {
			that.isTimeOn	=	false;
		})
	}
  	/**function for create otp form */
	public createOtpForm(){
		this.signupOtpForm					=	this.formBuilder.group({
			//'mobile_otp'				:	['', Validators.required],
			'email_otp'					:	['', Validators.required],
			'user_data'					:	[''],
		})

	}
	
	
	/**function for create OTP form */
	createOTPForMobileForm(){
		this.otpMobileForm			=	this.formBuilder.group({ 
			'user_data'					:	[''],
			'one_time_password'		:	['', Validators.required],
		});
	}//end createSignupSecondForm()
  
  
  	/**function for timer */
	public timeCounter(time:any){
		let nowdate =	new Date().getTime();
		let endTime	=	nowdate+(time*1000);
		this.countDownTime(endTime);
	}//end timer
	
	public countDownTime(endTime:any){
		let currenttime =	new Date().getTime();
		// Find the distance between now an the count down date
		let exacttime = endTime - currenttime;
		let exactminut = Math.floor((exacttime % (1000 * 60 * 60)) / (1000 * 60));
		let exactsec = Math.floor((exacttime % (1000 * 60)) / 1000);
		if(this.isTimeOn == false){
			exacttime	=	0;
		}
		if(exacttime>0){
			
			this.timeLeft	=	exactminut+':'+exactsec+' min left';
			
			let that	=	this;
			$(".timeLeft").html(that.timeLeft);
			setTimeout(function(){
				that.countDownTime(endTime);
			},1000);
			
		}else{
			this.timeLeft	=	'0:00 min left';
			this.isTimeOn	=	false;
			let that=	this;
			$(".timeLeft").html(that.timeLeft);
			$(".signup_resend").show();
			
			
		}
	}
	
	
	/**function for submit OTP*/
	submitSignupOTPForm() {
		if (this.signupOtpForm.valid) {
			blockUI();
			this.signupOtpForm.value.user_data			=	$("#signup_data").val();
			let method_name								=	'submitSignupOtp';
			this.ResetPasswordService.submitOTPForm(this.signupOtpForm.value,method_name).subscribe((res: any) => {		
				unblockUI();
				this.signupOtpFormStatus		=	res.data.success;	
				if(this.signupOtpFormStatus == true){
					this.signupOtpForm.reset();
					let that	=	this;
					$('body').removeClass('modal-open');
					$('#signup_verify').modal('hide');
					showSuccessCenter(res.data.message);
					that.router.navigate(['/login']);
				}else{
					this.signupOtpFormError 	= res.data.errors;
				}
			});
		} else {
			unblockUI();
			ValidationService.validateAllFormFields(this.signupOtpForm);
		}
	}//end submitOTPForm()
	
	
	/**function for resend otp */
	public resendSignupOtp(){
		blockUI();
		/**make object of form and append user id to form */
		let validate_string	=	$("#signup_data").val();	
		let formData        =   new FormData();
		this.user_data		=	validate_string;
		formData.append('user_data',this.user_data);
		let method_name		=	"resentSignupOtp";
		this.ResetPasswordService.resendOTP(formData,method_name).subscribe((res: any) => {	
			this.isTimeOn	=	true;
			this.timeCounter(this.resendMailTime);
			$(".signup_resend").hide();
			$('#signup_verify').modal('show');
			$('body').addClass('modal-open');
			showSuccessMessageTopRight(res.data.message);
			unblockUI();
		});
	}//end resend_otp()
  
  
  
  
  
   /**function for send otp */
	public sendOTP(){
		blockUI();
		/**make object of form and append user id to form */
		let validate_string	=	$("#signup_data_for_mobile").val();	
		let formData        =   new FormData();
		this.user_data		=	validate_string;
		formData.append('user_data',this.user_data);
		let method_name		=	"resentSignupOtpForMobile";
		this.ResetPasswordService.resendOTP(formData,method_name).subscribe((res: any) => {	
			this.isTimeOn	=	true;
			this.timeCounter(this.resendMailTime);
			$(".signup_resend").hide();
			$('#mobile_verify_on_signup').modal('show');
			$('body').addClass('modal-open');
			showSuccessMessageTopRight(res.data.message);
			unblockUI();
		});
	}
	
	/**function for submit OTP*/
	submitOTPFormForMobile() {
		if (this.otpMobileForm.valid) {
			blockUI();
			this.otpMobileForm.value.user_data			=	$("#signup_data_for_mobile").val();
			let method_name								=	'submitSignupOtpForMobile';
			this.ResetPasswordService.submitOTPForm(this.otpMobileForm.value,method_name).subscribe((res: any) => {		
				unblockUI();
				this.signupOtpFormStatus		=	res.data.success;	
				if(this.signupOtpFormStatus == true){
					this.otpMobileForm.reset();
					let that	=	this;
					$('body').removeClass('modal-open');
					$('#mobile_verify_on_signup').modal('hide');
					showSuccessCenter(res.data.message);
					that.router.navigate(['/login']);
				}else{
					this.signupOtpFormError 	= res.data.errors;
				}
			});
		} else {
			unblockUI();
			ValidationService.validateAllFormFields(this.otpMobileForm);
		}
	}//end submitOTPForm()
	
  



}
